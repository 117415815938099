<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
    <static-text />

    <div class="md:w-3/5 m-0 w-full justify-self-end">
      <custom-card title="Primeiro acesso">
        <h4>Olá {{ nome }}, tudo bem ?</h4>

        <p style="padding-top:20px;padding-bottom:20px;">
          Esse é o seu primeiro acesso, cadastre sua senha, aceite os termos de
          uso e clique em Iniciar primeiro acesso.
        </p>
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="refreshPassword()">
            <ValidationProvider
              name="Senha"
              rules="required|min:6"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                size="large"
                label="Senha"
                type="password"
                v-model="senha"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <br />
            <ValidationProvider
              name="Confirmação de Senha"
              rules="required|min:6"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                size="large"
                label="Confirmação de Senha"
                type="password"
                v-model="confirmacaoSenha"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <div @click="openModalTermosAceite()">
              <vs-checkbox class="mt-8 mb-4 mx-0" v-model="termosAceite">
                Sim, concordo com os termos de uso do Portal
              </vs-checkbox>
            </div>
            <vs-button
              :disabled="invalid"
              @click="refreshPassword()"
              class="w-full mt-8 uppercase"
              size="large"
              >Iniciar primeiro acesso</vs-button
            >
          </form>
        </ValidationObserver>
      </custom-card>
    </div>

    <vs-popup
      class="termos-modal"
      fullscreen
      :button-close-hidden="true"
      title="Termos de Uso"
      :active.sync="assinarNovoTermoDeUso"
    >
      <div class="flex flex-col items-center pt-4">
        <span v-html="newTermsOfUse.HTMLTermOfUse"></span>

        <vs-button
          @click="assignNewTermOfUse()"
          class="self-end mt-2 float-right"
          size="large"
          color="primary"
        >
          Li e concordo
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>
<script>
//Custom card

import { mapActions } from "vuex";
import CustomCard from "@/layouts/components/custom-card/CustomCard.vue";
import StaticText from "@/layouts/components/static-text/StaticText.vue";

import instance from "@/axios";


export default {
  components: {
    CustomCard,
    StaticText
  },
  data() {
    return {
      assinarNovoTermoDeUso: false,
      newTermsOfUse: {},
      tokenChangePasswordRequest: {},
      nome: "",
      senha: "",
      confirmacaoSenha: "",
      termosAceite: ""
    };
  },
  mounted () {

  },
  methods: {
    ...mapActions("alteracao-senha", [
      "validarTokenDeAcesso",
      "atualizarSenha"
    ]),

    refreshPassword() {
      if (!this.termosAceite) {
        return this.$onpoint.errorModal(
          "Você precisa aceitar os termos de aceite para prosseguir."
        );
      }

      if (this.senha !== this.confirmacaoSenha) {
        return this.$onpoint.errorModal(
          "Senha e confirmação de senha estão diferentes."
        );
      }

      this.$onpoint.loadingModal("Processando sua solicitação", () => {
        return this.atualizarSenha({
          UserInfoUniqueId: this.tokenChangePasswordRequest.UserInfoUniqueId,
          UserInfoChangePasswordRequestUniqueId: this.tokenChangePasswordRequest
            .UniqueId,
          NewPassword: this.senha,
          ConfirmNewPassword: this.confirmacaoSenha,
          AssignLastTermOfUse: true
        })
          .then(() => {
            this.$onpoint.successModal(
              "Senha alterada com sucesso. Você será redirecionado para tela de login."
            );
            //const dns = response.data.Response.CompanyDns != undefined ? response.data.Response.CompanyDns.toLowerCase().trim() : ''
            setTimeout(() => {
              this.$router.push("/");
              // if (dns != 'localhost') {
              //   this.$router.push(`/login/${dns}`)
              // } else {
              //   this.$router.push('/')
              // }
            }, 2000);
          })
          .catch(errors => {
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },

    openModalTermosAceite() {
      this.assinarNovoTermoDeUso = true;
    },

    assignNewTermOfUse() {
      this.assinarNovoTermoDeUso = false;
      this.termosAceite = true;
    },

    obterTermo() {
      return instance({
        method: "get",
        url: "/api/Core/GetTermOfUse?userId="+this.tokenChangePasswordRequest.UserInfoUniqueId
      }).then(termsData => {
        this.newTermsOfUse = termsData.data.Response;
      });
    }
  },
  beforeMount() {
    this.$vs.loading();
    this.validarTokenDeAcesso(this.$route.params.token).then(data => {
      this.tokenChangePasswordRequest = data.Response;
      this.nome = data.usuario;
      this.obterTermo();
      this.$vs.loading.close();
    })
      .catch(() => {
        this.nome = "Visitante";
        setTimeout(() => {
          this.$vs.loading.close();
          this.$router.push("/");
        }, 2000);
      });
  },
  created() {
    this.$appInsights.trackPageView({
      name: "page-primeiro-acesso",
      url: window.location.href
    });
  },
};
</script>
<style scoped lang="scss">
.termos-modal {
  ::v-deep .vs-popup {
    width: 800px;
  }
  ::v-deep .vs-popup--header {
    background: white;

    .vs-popup--title {
      margin-top: 2em;
      text-align: center;
      color: var(--vs-primary);
    }
  }
}

h4 {
  color: inherit;
}

.justify-self-end {
  justify-self: end;
}
</style>
